<script>
import { computed } from 'vue'
import { useLanguageStore } from '@/stores/language.js'

export default {
  name: 'Terms',
  setup() {
    const languageStore = useLanguageStore()

    const showAttention = computed(() => {
      return languageStore.lang !== 'ja'
    })

    return {
      showAttention
    }
  }
};
</script>

<template>
  <main>
    <section class="terms_tittle">
      <h1>{{ $t("termsTitle") }}</h1>
    </section>
    <section>
      <!-- 言語が ja 以外のときに表示する -->
      <p v-if="showAttention" class="not-ja">
        This Terms applies to all services provided by JustFit Co., Ltd. (hereinafter referred to as "JustFit").
        If there is any discrepancy between the Japanese version and the translated version, the Japanese version shall prevail.
      </p>
    </section>
    <section class="terms_container">
      <p class="terms_preface">
        株式会社JustFitの提供するサービス（以下「本サービス」といいます。）をご利用頂いた場合、
        本規約の内容を理解しており、かつ、本規約の全ての条項について承諾したものとみなします。
        なお、本規約の内容は、必要に応じて変更しますので、本サービスをご利用する際には、最新の利用規約を確認して下さい。
      </p>
      <div class="term">
        <h2>第１条（用語の定義）</h2>
        <p>本規約において使用する以下の用語の意味は、各々以下に定めるとおりとします。</p>
        <ul class="list_decimal">
          <li>
            「本サービス」とは、株式会社JustFit（以下「当社」といいます。）が運営するサービスから構成される
            化粧品メディアサイト、及び当該ウェブサイトが提供するサービスの総称をいいます。
          </li>
          <li>
            「コンテンツ提供者」とは、当社の委託を受けて本サービスにおいて化粧品の記事やコラム等を
            執筆、作成、監修又は投稿する方をいいます。
          </li>
          <li>「本利用者」とは、本サービスの閲覧者及びコンテンツ提供者を含む本サービスを利用する全ての方をいいます。</li>
        </ul>
      </div>
      <div class="term">
        <h2>第２条（本規約の範囲と変更）</h2>
        <ul class="list_decimal">
          <li>
            本規約は、本サービスの提供条件及び本サービスの利用に関する当社と本利用者との間の権利義務関係を定めることを目的とし、
            本利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。
          </li>
          <li>
            当社は、本利用者の承諾を得ることなく、当社が適当と判断する方法で本利用者に通知することにより、
            本規約を変更できるものとします。
          </li>
          <li>
            当社が本サービス用サイト上で掲載する本サービスの利用に関するルールは、そのルールの名称を問わず、
            本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが矛盾・抵触する場合は、
            当該説明等の規定を優先させる旨の特段の定めがない限り、本規約の規定が優先して適用されるものとします。
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第３条（利用停止等）</h2>
        <ul class="list_decimal">
          <li>
            本利用者が、以下の各号の一つに該当する場合、当社は、当該本利用者に対して事前に通知することなく、
            当該本利用者による本サービス用サイトへのアクセスを禁止する等の適切な措置をとることができるものとします。
            なお、当社は当該措置を行った理由について、当該本利用者に開示する義務を負いません。
            <ul class="list_upperLatin">
              <li>本規約またはガイドラインに違反したとき</li>
              <li>
                本利用者が当社のコンピューターに保存されているデータを当社に無断で閲覧、変更若しくは破壊したとき、
                又はそのおそれがあると当社が判断したとき
              </li>
              <li>本利用者の本サービス利用態様が公序良俗に反すると当社が判断したとき</li>
              <li>
                自ら又は第三者を利用して、次のaからeのいずれかに該当する行為を行ったとき
                <ul class="list_lowerLatin">
                  <li>暴力的な要求行為</li>
                  <li>法的な責任を超えた不当な要求行為</li>
                  <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
                  <li>風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</li>
                  <li>その他、aからdのいずれかに準ずる行為</li>
                </ul>
              </li>
              <li>その他、当社が当該本利用者に対し本サービスの提供を継続することが不適当であると判断したとき</li>
            </ul>
          </li>
          <li>
            前項に定める措置は、当社から当該本利用者に対する損害賠償請求を行うことを妨げるものではありません。
            また、前項に定める措置を行ったことについて、当社は一切の損害賠償義務、金銭返還義務を負いません。
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第４条（サービス提供の一時停止）</h2>
        <ul class="list_decimal">
          <li>
            当社は、以下のいずれかに該当する場合には、本利用者に事前に通知することなく、
            本サービスの全部又は一部の提供を停止又は中断することができるものとします。
            <ul class="list_upperLatin">
              <li>システムの点検又は保守を緊急に行う場合</li>
              <li>コンピューター、通信回線が事故や第三者からの攻撃によって停止した場合</li>
              <li>地震、落雷、火災などの不可抗力によって本サービスの運営ができなくなった場合</li>
              <li>その他、当社が停止又は中断を行う必要があると判断した場合</li>
            </ul>
          </li>
          <li>当社は、本条に基づき当社が行った措置に基づき本利用者に生じた損害について一切の責任を負いません。</li>
        </ul>
      </div>
      <div class="term">
        <h2>第５条（禁止行為）</h2>
        <ul class="list_decimal">
          <li>
            本利用者は、本サービスの利用に当たり、以下の行為を行ってはならないものとします。
            <ul class="list_upperLatin">
              <li>
                本サービスの提供する情報（全部、一部を問いません）を、当社の事前の同意なしに、複写もしくはその他の方法により
                再生、複製、送付、譲渡、頒布、配布、転売又はこれらの目的で使用するために保管する行為
              </li>
              <li>本規約に違反する行為</li>
              <li>他人の知的財産権を侵害する行為</li>
              <li>他人のプライバシーを侵害する行為</li>
              <li>他人の名誉・信用等を侵害する行為</li>
              <li>公序良俗に反する行為</li>
              <li>犯罪的行為又は犯罪的行為に結びつく行為</li>
              <li>事実に反した情報及び架空の情報を提供する行為</li>
              <li>研究・学術調査を目的として情報を提供する行為</li>
              <li>当社が特に許可をした場合を除き、営業、宗教活動および政治活動を目的とする情報を提供する行為</li>
              <li>当社や本サービスの運営を妨げたり、信用を毀損したりする行為</li>
              <li>当社の管理するサーバーに対して、コンピュータウィルスなどの有害なプログラムを配信する行為</li>
              <li>
                本サービス及び本サービスに関連して使用されている全てのソフトウェア又はプログラム等について
                リバースエンジニアリング、逆コンパイル、逆アセンブルその他改変等を行う行為
              </li>
              <li>法令に違反する行為又は法令に違反するおそれのある行為</li>
              <li>その他、当社が不適切であると判断する行為</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第６条（個人情報等の取り扱い）</h2>
        <ul class="list_decimal">
          <li>
            当社は、本利用者が当社に提供したすべての情報（以下総称して「提供個人情報」といいます。）を
            本サービスの「プライバシーポリシー」に基づき、適切に取り扱うものとします。
          </li>
          <li>
            当社の提携先企業や広告主企業のウェブサイト等は本サービスとは別個のプライバシーポリシーを設けていますので、
            それぞれご確認ください。なお、当社はこれらの独立した規約や活動に対していかなる義務や責任も負っておりません。
          </li>
          <li>
            当社は本サービス運営事業を他社に譲渡した場合、当該事業譲渡に伴い本規約上の地位、
            本規約に基づく権利及び義務並びに本利用者の情報、提供個人情報その他の情報を当該事業譲渡の譲受人に
            譲渡することができるものとし、本利用者は、かかる譲渡につき本項において予め同意したものとします。
            なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転する
            あらゆる場合を含むものとします。
          </li>
          <li>
            当社は、本サービスを通じて得た情報や本利用者から提供又は入力された情報を、情報の分析や評価をし、
            第三者への情報提供等のために利用することがあります。
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第７条（知的財産権）</h2>
        <ul class="list_decimal">
          <li>
            本サービスに含まれているコンテンツ、個々の情報に関する権利は当社及び本サービスにコンテンツ等を
            提供している提携先企業に帰属しています。
          </li>
          <li>
            本サービス又は広告の中に提供、掲載されているコンテンツは、
            著作権法、商標法、意匠法等の各種法令によって保護されています。
          </li>
          <li>
            本利用者は当社、提携先企業、広告主企業等の事前の承諾を得た場合を除いて、本サービス若しくはソフトウェア又は
            それらに含まれる内容を複製、公開、譲渡、貸与、翻訳、転売、転送、使用許諾、再利用等してはならないものとします。
            また、本利用者がこれらの行為によって受けた損害に関し、当社は、一切の保証をしないと同時に、
            本利用者がこれらの行為を行った場合、損害賠償請求をすることがあります。
          </li>
          <li>
            本利用者が当社に提供したすべての情報（以下「提供情報」といいます。）に関する著作権その他の権利
            （著作権法第27条及び第28条の権利を含みます。）は、本利用者が本サービスに対して当該情報を送信した時点で
            当社にすべて譲渡されます。また、本利用者は、提供情報に関する著作者人格権（公表権、氏名表示権、同一性保持権）を
            行使しないこともあらかじめ承諾するものとします。
          </li>
          <li>
            前項の規定にかかわらず、当社は本利用者からの提供情報について、当該情報の提供を行った本利用者自身が利用することを
            無償で許諾するものとします。ただし、本利用者がこれにより利益を得ることは禁止し、
            本利用者が提供情報の利用により利益を得た場合は、当社に当該利益相当額を支払うものとします。
          </li>
          <li>本利用者は提供情報の権利帰属に関して、当社に対して、いかなる権利の主張及び行使も行わないものとします。</li>
        </ul>
      </div>
      <div class="term">
        <h2>第８条（サービスの変更・追加・廃止）</h2>
        <ul class="list_decimal">
          <li>
            当社は、理由の如何を問わず、本利用者に事前に通知することなく、
            本サービスの内容の全部又は一部を変更又は追加することができるものとします。
          </li>
          <li>
            当社は、本利用者に通知の上、本サービスの全部又は一部を中止又は廃止することができるものとします。
            ただし、本サービスの全部又は一部を中止又は廃止する緊急の必要性がある場合は、事前の通知を行うことなく、
            中止又は廃止をすることができるものとします。
          </li>
          <li>
            当社は、本サービスを変更、追加、中止又は廃止したことにより本利用者又は第三者に生じた損害について、
            一切責任を負わないものとします。
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第９条（免責事項）</h2>
        <ul class="list_decimal">
          <li>
            当社は、本サービス又は本サービスが提携するサービスの提供中止、停止、故障等により、損害が生じたとしても、
            これについて一切の責任を負わないものとします。
          </li>
          <li>
            本利用者は、本サービス又は本サービスが提携するサービスにおいて、下記の事情により一定期間、
            本サービス又は本サービスが提携するサービスの利用が停止される場合があることを予め承諾し、
            本サービス又は本サービスが提携するサービスの停止による損害の補償等を当社に請求しないこととします。
            <ul class="list_upperLatin">
              <li>本サービス又は本サービスが提携するサービスのサーバー、ソフトウェア等の点検、修理、補修等のための停止の場合</li>
              <li>コンピューター、通信回線等の事故による停止の場合</li>
              <li>その他、やむをえない事情による停止が必要であると当社が判断し、停止を行った場合</li>
            </ul>
          </li>
          <li>
            当社はいかなる状況においても、またいかなる方に対しても、以下の各号に定める事項について一切責任を負いません。
            <ul class="list_upperLatin">
              <li>
                本サービスを通じて提供される情報の入手、収集、編纂、解釈、分析、編集、翻訳、送付、伝達、配布に関わる
                誤り（当社の不注意によるか、その他によるかを問わず）又はその他の状況により（全部、一部を問わず）引き起こされ、
                発生し、若しくはこれらに起因する損失又は損害
              </li>
              <li>
                本サービスを通じて提供される情報の使用又は使用不可能により発生する、あらゆる種類の直接的、間接的、特別、
                二次的、又は付随的な損害（このような損害の可能性について当社が事前に通告を受けたかどうかを問いません。）
              </li>
              <li>
                当社は、コンテンツ提供者が提供、助言、送信、公開したすべての情報の完全性、正確性、確実性、有効性、
                安全性、合目的性等について、いかなる保証もせず、その内容から発生するあらゆる問題について一切の責任を負いません。
              </li>
              <li>
                本サービスを通じて、本利用者が取得する情報については、その完全性、正確性、確実性、有効性、安全性、
                合目的性等につき、すべて本利用者の自己の責任と判断のもとで利用するものとし、その内容から発生する
                あらゆる問題について当社は一切の責任を負いません。また、本サービスからリンクされているサイトの情報についての責任、
                あるいはその内容から発生するあらゆる問題について当社は一切の責任を負いません。
              </li>
              <li>
                本サービス又は本サービスが提携するサービスにおいて、本利用者間に生じたトラブル、損害その他の一切の事項に対して、
                当社はいかなる責任も負わず、補償を行いません。
              </li>
              <li>
                本利用者間で生じたトラブルについては、全て当該当事者間での交渉等により解決頂くものとし、当社は両者間の取り次ぎ、
                交渉、情報提供その他一切のやり取りについて関与する義務を負いません。
              </li>
              <li>
                当社は、下記の各号に該当する記載を発見した場合、予告なく、当該記載を削除し、又は記載の修正を行う場合があります。
                なお、削除や修正対象に該当するか否かの判断は、全て当社が行い、当社は、削除や修正を行った理由について、
                開示する義務を負いません。また、削除や修正に起因して損害が生じたとしても、当社は、一切の責任を負いません。
                <ul class="list_lowerLatin">
                  <li>本規約に反する記載</li>
                  <li>公序良俗に反する記載</li>
                  <li>有害なプログラム・スクリプトなどを含む記載</li>
                  <li>他人の名誉・信用を害する記載</li>
                  <li>本サービスの運営を妨げる記載</li>
                  <li>その他、当社が不適切であると判断した記載</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            当社は、当社が提供するサービス又は本サービスが提携するサービスの、デザイン等の仕様を予告なく変更することができるものとし、
            当該変更によって本利用者に生じた損害について一切責任を負わないものとします。
          </li>
          <li>
            本規約に定める免責条項が適用されない等の理由により、当社が本利用者又は第三者に対して責任を負うべき場合、
            当社に故意又は重過失がある場合を除き、それらの責任に基づく損害賠償額はいかなる場合でも金1万円を上限額とすることに、
            当社及び本利用者は予め合意します。なお、本利用者は、本利用者がこの上限額の定めに同意しない限り
            当社が本サービスを提供しないことを十分に理解の上で、予め同意し承諾するものとします。
          </li>
        </ul>
      </div>
      <div class="term">
        <h2>第１０条（反社会的勢力への不関与）</h2>
        <ul class="list_decimal">
          <li>
            本利用者は、自身が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、
            特殊知能暴力集団、その他反社会的勢力（以下｢暴力団等反社会的勢力｣といいます。）に所属又は該当せず、
            かつ、暴力団等反社会的勢力と関与していないことを表明し、将来にわたっても所属若しくは該当、
            又は関与しないことを確約するものとします。
          </li>
          <li>
            当社は、本利用者が暴力団等反社会的勢力に所属若しくは該当する、又は正当な理由なく関与していると判断した場合、
            当該本利用者に事前に通知等を行うことなく、当該本利用者の本サービスサイトへのアクセスを禁止し、
            当該本利用者が行った書込みの削除等の適切な措置を講じることがあります。
          </li>
          <li>当社は前項の措置による本利用者の損害を賠償する責任を一切負わないものとします。</li>
        </ul>
      </div>
      <div class="term">
        <h2>第１１条（分離可能性）</h2>
        <p>
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、
          本規約の残りのその他の条項、及び一部が無効又は執行不能と判断された条項の残りの部分は、継続して完全にその効力を有するものとします。
        </p>
      </div>
      <div class="term">
        <h2>第１２条（準拠法、合意管轄）</h2>
        <p>
          本利用者と当社との間における紛争については、日本国法を適用し、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          また、本規約は、日本国法に準拠し、解釈されるものとします。
        </p>
      </div>
      <p class="terms_enactment">
        2020年3月&nbsp;&nbsp;制定・施行
      </p>
    </section>
  </main>
</template>

<style scoped>
h1{
  font-size: 24px;
  font-weight: bold;
}
h2{
  font-size: 22px;
  font-weight: bold;
}
p, li{
  font-size: 14px;
}
ul{
  list-style-position: inside;
}
.terms_tittle{
  padding: 0 40px;
  background-color: #fff;
  text-align: center;
}
.terms_tittle h1{
  padding: 40px;
}
.terms_container{
  padding: 0 40px;
  background-color:#F5EAE7;
}
.terms_preface{
  padding: 55px 0 30px 0;
  display: block;
}
.term{
  padding: 25px 0;
}
.term p{
  padding: 10px 0;
}
.term li{
  padding: 5px 0;
}
.list_decimal{
  padding-top: 25px;
  list-style: decimal;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
.list_upperLatin{
  list-style: upper-latin;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
.list_lowerLatin{
  list-style: lower-latin;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
.terms_enactment{
  display: block;
  padding: 30px 0;
  text-align: right;
}

/* 日本語じゃないときはアクティブにする */
.not-ja {
  display: block;
}
</style>
